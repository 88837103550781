import { Link } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { useEffect, useState, useContext} from "react";
import CustomSelect from "./CustomSelect";
import { UserContext } from "../Context";
import FetchingData from "./FetchingData";

export default function Sidebar(props) {
    const router = useLocation()
    const navigate = useNavigate()

    const { user, toggleUser } = useContext(UserContext);

    const [isOpen, setIsOpen] = useState();
    const [organizationList, setOrganizationList] = useState([])
    const [superUserT, setSuperUserT] = useState('')


    useEffect(() => {
        if (user) {
            if (user.scope){
                setOrganizationList(user.scope)
                setSuperUserT(user.user)
            } else {
                const {organization, superUser} = props
                setOrganizationList(organization)
                setSuperUserT(superUser)
            }
        } else {
            console.log('redirecting')
            // redirect to login
            navigate('/')
        }
    }, [user, props, navigate])

    const selectOptions = organizationList.map((org) => {
        return {
            value: org.organization_id,
            label: org.name
        }
    })

    useEffect(() => {
        if (router.pathname === "/hardtags" || router.pathname === "/checkout") {
            setIsOpen(true);
        }
    }, [])

    const handleSelectChange = (value) => {
        toggleUser(value, superUserT, organizationList)
        navigate('/home')
      };

    const logoutClick = async () => {
        const response = await FetchingData('/api/logout', undefined, 'POST')
        // set context to null
        await toggleUser(undefined)
        navigate('/')
        console.log('logout response', response)
    }

    const linkClass = (href) => {
        return router.pathname === href ? 
        "flex items-center p-2 text-base font-normal rounded-lg text-white bg-gray-700 group" : 
        "flex items-center p-2 text-base font-normal rounded-lg text-white hover:bg-gray-700 group";
    }
    return (
        <>
            <aside className="fixed top-0 left-0 w-48 h-full" aria-label="Sidenav">
                <div className="overflow-y-auto py-5 px-3 h-full border-r bg-gray-800 border-gray-700">
                    <ul className="space-y-2">
                        <li>
                            <Link to="/home" className="flex items-center p-2 pb-8 mb-8 text-lg font-semibold rounded-lg text-white group border-b border-gray-700">
                                <span className="ml-3 ">Value Dashboard</span>
                            </Link>
                        </li>
                        <li>
                        <Link to="/receive" className={linkClass("/receive")}>
                                <span className="flex-1 ml-3 whitespace-nowrap ">Goods Receiving</span>
                            </Link>
                        </li>
                        <li>
                        <Link to="/count" className={linkClass("/count")}>
                                <span className="flex-1 ml-3 whitespace-nowrap">Year end Count</span>
                            </Link>
                        </li>
                        <li>
                            <Link to="/onshelf" className={linkClass("/onshelf")}>
                                <span className="flex-1 ml-3 whitespace-nowrap">On Shelf Availability</span>
                            </Link>
                        </li>
                        <li>
                        <Link to="/critical" className={linkClass("/critical")}>
                                <span className="flex-1 ml-3 whitespace-nowrap">Critical Zero</span>
                            </Link>
                        </li>
                        <li>

                            { isOpen ? (
                                <>
                                    <button type="button" onClick={() => setIsOpen(!isOpen)} className="flex items-center p-2 w-full text-base font-normal rounded-lg transition duration-75 group text-white hover:bg-gray-700" aria-controls="dropdown-authentication" data-collapse-toggle="dropdown-authentication">
                                    <span className="flex-1 ml-3 text-left whitespace-nowrap">Untap</span>
                                    <svg aria-hidden="true" className="w-6 h-6 rotate-180" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                    </button>
                                    <li>
                                        <Link to="/hardtags" className={linkClass("/hardtags")}>
                                            <span className="flex-1 ml-3 whitespace-nowrap ml-8">Hardtags</span>
                                        </Link>
                                    </li>
                                    <li>
                                        <Link to="/checkout" className={linkClass("/checkout")}>
                                            <span className="flex-1 ml-3 whitespace-nowrap ml-8">Checkout</span>
                                        </Link>
                                    </li>
                                </>

                                    ) :
                                    (
                                            <button type="button" onClick={() => setIsOpen(!isOpen)} className="flex items-center p-2 w-full text-base font-normal rounded-lg transition duration-75 group text-white hover:bg-gray-700" aria-controls="dropdown-authentication" data-collapse-toggle="dropdown-authentication">
                                            <span className="flex-1 ml-3 text-left whitespace-nowrap">Untap</span>
                                            <svg aria-hidden="true" className="w-6 h-6 transform" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg"><path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path></svg>
                                            </button>
                                    )
                            }
                        </li>
                    </ul>
                    <ul className="pt-5 mt-5 space-y-2 border-t border-gray-700">
                        <li>
                            <Link to="/settings" className="flex items-center p-2 text-base font-normal rounded-lg transition duration-75 hover:bg-gray-700 text-white group">
                                <span className="ml-3">Settings</span>
                            </Link>
                        </li>
                        <li>
                            <button onClick={() => logoutClick()} className="flex items-center p-2 text-base font-normal rounded-lg transition duration-75 hover:bg-gray-700 text-white group ml-3">
                                Logout
                            </button>
                        </li>
                    </ul>
                    {
                        superUserT === 'superuser' && (
                            <ul className="pt-5 mt-5 space-y-2 border-t border-gray-700">
                                <li>
                                    <Link to="/addUser" className="flex items-center p-2 text-base font-normal rounded-lg transition duration-75 hover:bg-gray-700 text-gray-400 group">
                                        <span className="ml-3">Add User</span>
                                    </Link>
                                </li>
                                <li>
                                    <Link to="/addOrganization" className="flex items-center p-2 text-base font-normal rounded-lg transition duration-75 hover:bg-gray-700 text-gray-400 group">
                                        <span className="ml-3">Add Organization</span>
                                    </Link>
                                </li>
                            </ul>
                        )
                    }
                    
                    <div className="absolute bottom-0 left-0 w-full p-3">
                        {
                            <div className="mb-4">
                                <p className="ml-1 text-xs text-gray-400">Organization:</p>
                                <CustomSelect options={selectOptions} onChange={handleSelectChange} current={user?.organization_id}/>
                            </div>
                        }
                        <Link to="/versionHistory" className="text-xs text-gray-500 text-center">Version 0.1.6 (beta)</Link>
                    </div>
                </div>
            </aside>
        </>
    )
  }
