import {BrowserRouter as Router, Routes, Route} from "react-router-dom";
import Receive from "./pages/receive";
import Count from "./pages/count";
import Onshelf from "./pages/onshelf";
import Critical from "./pages/critical";
import Hardtags from "./pages/hardtags";
import Checkout from "./pages/checkout";
import Settings from "./pages/settings";
import NotFound from "./pages/notfound";
import AddUser from "./pages/addUser";
import AddOrganization from "./pages/addOrganization";
import App from "./App";
import Home from "./pages/home";
import VersionHistory from "./pages/versionHistory";
export default function Currentroutes() {
  return (
    <Router>
      <Routes>
        <Route path="*" element={<NotFound />} />
        <Route path="/" element={<App />} />
        <Route path="/home" element={<Home />} />
        <Route path="/receive" element={<Receive />} />
        <Route path="/count" element={<Count />} />
        <Route path="/onshelf" element={<Onshelf />} />
        <Route path="/critical" element={<Critical />} />
        <Route path="/hardtags" element={<Hardtags />} />
        <Route path="/checkout" element={<Checkout />} />
        <Route path="/settings" element={<Settings />} />
        <Route path="/addUser" element={<AddUser />} />
        <Route path="/addOrganization" element={<AddOrganization />} />
        <Route path="/versionHistory" element={<VersionHistory />} />
      </Routes>
    </Router>
  )
}

