import Sidebar from "../components/Sidebar";
import { useEffect, useState, useContext } from "react";
import { useNavigate } from 'react-router-dom';
import MainDashboard from "../components/MainDashboard";
import { UserContext } from "../Context";
import FetchingData from "../components/FetchingData";
import BrowserWarning from "../components/BrowserAlarm";

export default function Home() {
    const navigate = useNavigate();
    const { user, toggleUser } = useContext(UserContext);
    const [userData, setUserData] = useState()

    useEffect(() => {
      const syncData = async () => {
       const response = await FetchingData('/api/retrieve-scope', undefined, 'GET')
       if (response.error) {
        navigate('/')
       } else {
           if (!user){
            toggleUser(response.organization[0]?.organization_id, response?.user?.role, response.organization)
           }
       }
    }
    syncData()
    }, [])

    useEffect(() => {
    const syncData = async () => {
       const response = await FetchingData(`/api/user-settings?organizationId=${user.organization_id}`, undefined, 'GET')
       if (response.error) {
            console.log(response.error)
       } else {
            setUserData(response)
       }
        }
    if (user) {
        syncData()
    }
    }, [user])

  return (
    <>
        <BrowserWarning />
        {
            user ? (            
                <>
                <div className="flex bg-gray-700">
                    <Sidebar />
                    <main className="flex-grow ml-48 relative">
                    <MainDashboard userData={userData} />
                    </main>
                </div>
                </>
            ) : (
                <div className="flex bg-gray-700 w-full h-screen place-content-center">
                    <p className="text-white text-lg m-auto">Loading</p>
                </div>
            )
        }
    </>
  );
}