import Sidebar from '../components/Sidebar';
import { useState, useContext } from 'react';
import { UserContext } from '../Context';


export default function VersionHistory() {
    const { user } = useContext(UserContext)
    const [versionHistory] = useState([
        {
            version: '0.1.6',
            date: '2025-02-10',
            changes: [
                'Fix the calculation of checkout and hardtags. Taking out the number of stores as Item sold per year is based on the organization (Items sold per year / Average basket size * Checkout time saving / 3600 * Price per working hour)',
                'Change the calculations on the main screen based on the new calculation of checkout and hardtags',
                'Add the new version history page, explaining the changes in the version',
                'Change the naming for untapped to untap',
                'Change the Currently title in the main screen to Current'
            ]
        }
        // Add more versions as needed
    ]);


    return (
        <>
            <div className="flex flex-col bg-gray-700">
                <Sidebar />
                <main className="flex-grow ml-48 relative h-screen">
                    <section className='p-8 bg-gray-700'>
                        <div className="w-full flex flex-col bg-gray-700">
                            <h1 className="text-2xl text-white mb-8">Version History</h1>
                            {versionHistory.map((version, index) => (
                                <div key={index} className="mb-4 p-4 bg-gray-800 shadow rounded border border-slate-500">
                                    <h2 className="text-xl text-white">{`Version ${version.version} - ${version.date}`}</h2>
                                    <ul className="list-disc list-inside text-gray-400">
                                        {version.changes.map((change, idx) => (
                                            <li key={idx}>{change}</li>
                                        ))}
                                    </ul>
                                </div>
                            ))}
                        </div>
                    </section>
                </main>
            </div>
        </>
    );
}
