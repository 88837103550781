import React, { useEffect, useState } from 'react';

const BrowserWarning = () => {
  const [showWarning, setShowWarning] = useState(false);

  useEffect(() => {
    const userAgent = window.navigator.userAgent;
    const isIE = /MSIE|Trident/.test(userAgent);
    const isOldEdge = /Edge\/\d./i.test(userAgent);

    if (isIE || isOldEdge) {
      setShowWarning(true);
    }
  }, []);

  if (!showWarning) return null;

  return (
    <div style={{ padding: '10px', backgroundColor: 'red', color: 'white', textAlign: 'center' }}>
      <p>
        You are using an unsupported browser. For the best experience, please use a modern browser like Chrome, Firefox, or Safari.
      </p>
    </div>
  );
};

export default BrowserWarning;
