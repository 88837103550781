import AreaChartStandard from '../components/AreaChartStandard';
import Sidebar from '../components/Sidebar';
import LineChartStandard from '../components/LineChartStandard';
import CalculationBox from '../components/CalculationBox';
import AssumptionCard from '../components/AssumptionCard';
import FetchingData from '../components/FetchingData';
import { useEffect, useState, useContext } from 'react';
import { UserContext } from '../Context';

export default function Checkout() {
    const { user } = useContext(UserContext)
    const [userData, setUserData] = useState()

    useEffect(() => {
        const response = FetchingData(`/api/user-settings?organizationId=${user.organization_id}`, undefined, 'GET')
        response.then(res => {
            if (!res.error){
                setUserData(res)
            } else {
                console.log(res.error)
            }
        })
    }, [])
  return (
    <>
    <div className="flex flex-col bg-gray-700">
    <Sidebar/>
    <main className="flex-grow ml-48 relative h-screen">
        <section className='p-8 bg-gray-700'>
            <div className="w-full flex flex-col bg-gray-700">
                <h1 className="text-2xl text-white mb-8">Results</h1>
                <div className="flex w-full gap-2 mb-4">                   
                    <CalculationBox
                        title='Speed at Checkout'
                        children={[
                            {
                                title: 'Transactions',
                                calculation: [userData?.items_sold_per_year ?? 'N/A', '/', userData?.average_basked_size ?? 'N/A'],
                                result: (parseFloat(userData?.items_sold_per_year ?? 0) / parseFloat(userData?.average_basked_size ?? 0)).toFixed(0)
                            },
                            {
                                title: 'Transactions time',
                                calculation: [(parseFloat(userData?.items_sold_per_year ?? 0) / parseFloat(userData?.average_basked_size ?? 0)).toFixed(0), 'x', userData?.checkout_time_saving ?? 'N/A', '/', 3600],
                                result: ((parseFloat(userData?.items_sold_per_year ?? 0) / parseFloat(userData?.average_basked_size ?? 0) * parseFloat(userData?.checkout_time_saving ?? 0)) / 3600).toFixed(0)
                            },
                            {
                                title: 'Save price',
                                calculation: [((parseFloat(userData?.items_sold_per_year ?? 0) / parseFloat(userData?.average_basked_size ?? 0) * parseFloat(userData?.checkout_time_saving ?? 0)) / 3600).toFixed(0), 'x', userData?.price_per_working_hour ?? 'N/A'],
                                result: ((parseFloat(userData?.items_sold_per_year ?? 0) / parseFloat(userData?.average_basked_size ?? 0) * parseFloat(userData?.checkout_time_saving ?? 0)) / 3600 * parseFloat(userData?.price_per_working_hour ?? 0)).toFixed(0)
                            }

                        ]}
                    />
                    <div className="w-3/5 h-80 bg-gray-800 shadow rounded border border-slate-500">
                        <AreaChartStandard height={'100%'} />
                    </div>
                </div>
            </div>
            <h1 className="text-xl font-bold text-white mb-8">Assumptions</h1>
            <div className="flex flex-wrap gap-2">
                <AssumptionCard title={'Basket Size'} children={{value: userData?.average_basked_size ?? 'N/A', units: '/ items', description: 'Average number of products sold in the same ticket'}} />
                <AssumptionCard title={'Time saving'} children={{value: userData?.checkout_time_saving ?? 'N/A', units: '/ seconds', description: 'Time saved using and RFID vs the traditional way'}} />
                <AssumptionCard title={'EAS Active Stores'} children={{value: userData?.eas_active_stores ?? 'N/A', units: '/ stores', description: 'Active Stores using RFID EAS'}} />
                <AssumptionCard title={'Items sold'} children={{value: userData?.items_sold_per_year ?? 'N/A', units: '/ items', description: 'Number of items sold per year'}} />
            </div>
        </section>
    </main>
    </div>
    </>
  )
}